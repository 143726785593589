import React, { ReactElement, useMemo } from "react";
import { useDomainContext } from "@hooks/useDomainContext";

import VerticalOffer from "@components/shared/offerVertical";
import { useRouter } from "next/router";
import { affiliateOfferZones } from "@lib/shared/config";
import { doesOfferHaveALink, shouldRemoveBuyer } from "@lib/sharedUtils";

export default function OffersColumn(): ReactElement {
    const { thankYouOffers } = useDomainContext();
    const { query } = useRouter();
    const { eb } = query;

    const stickyOffer = useMemo(() => {
        if (thankYouOffers && thankYouOffers.length) {
            const offer = thankYouOffers.find((offer) => offer?.sticky);
            if (eb && offer) {
                return shouldRemoveBuyer(eb, offer) ? null : offer;
            }
            return offer;
        }
        return null;
    }, [thankYouOffers]);

    const finalThankYouOffers = useMemo(() => {
        if (thankYouOffers && thankYouOffers.length && stickyOffer) {
            const filteredOffers = thankYouOffers.filter(
                (offer) => !shouldRemoveBuyer(eb, offer),
            );
            return filteredOffers.filter((offer) => {
                return offer.id !== stickyOffer.id;
            });
        }
        return thankYouOffers?.filter((offer) => !shouldRemoveBuyer(eb, offer));
    }, [thankYouOffers]);

    return finalThankYouOffers?.length || stickyOffer ? (
        <>
            <div className="w-full pt-10 md:w-2/4 lg:w-1/3 px-0 sm:px-12">
                {stickyOffer ? (
                    <VerticalOffer
                        zone={affiliateOfferZones.THANK_YOU}
                        offer={stickyOffer}
                        displayLinkAndPhoneNumber={doesOfferHaveALink(
                            stickyOffer,
                        )}
                    />
                ) : (
                    <></>
                )}
                {finalThankYouOffers?.map((offer, index) => (
                    <VerticalOffer
                        zone={affiliateOfferZones.THANK_YOU}
                        key={index}
                        offer={offer}
                        displayLinkAndPhoneNumber={doesOfferHaveALink(offer)}
                    />
                ))}
            </div>
        </>
    ) : (
        <></>
    );
}
